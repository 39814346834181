/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useRef } from 'react';
import { graphql, navigate } from 'gatsby';
import Slider from 'react-slick';
import Layout from '../components/layout';
import { homePageSlides } from '../data/data';
import loadable from '@loadable/component';
import '../styles/index.scss';

const Grid = loadable(() => import('../components/grid'));
const CardWithoutImage = loadable(() => import('../components/card-without-image'));

const settings = {
  autoplaySpeed: 8000,
  arrows: false,
  dots: true,
  infinite: true,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  clickable: true,
  lazyLoad: 'progressive'
};

const IndexPage = ({ data }) => {
  const [isSwiping, setSwiping] = useState(false);

  const metaData = {
    title: 'Архитектура проектиране интериор дизайн'
  };

  const headElementRef = useRef();

  return (
    <Layout metaData={metaData} headElementRef={headElementRef} ad={data.ad}>
      <Slider {...settings}>
        {homePageSlides.map(item => {
          const queryImage = data.homeImages.edges.find(query => item.image === query.node.base);
          const queryVideo = data.homeVideos.edges.find(query => item.video === query.node.base) || null;
          const queryData = { image: queryImage, video: queryVideo };
          return (
            <div
              role="button"
              tabIndex="0"
              key={queryData.image.node.id}
              onMouseDown={() => {
                setSwiping(false);
              }}
              onMouseMove={() => {
                setSwiping(true);
              }}
              onMouseUp={e => {
                if (!isSwiping && e.button === 0) {
                  navigate(item.url);
                }
              }}
              onTouchStart={() => {
                setSwiping(false);
              }}
              onTouchMove={() => {
                setSwiping(true);
              }}
              onTouchEnd={e => {
                e.preventDefault();

                if (!isSwiping) {
                  navigate(item.url);
                }
              }}
            >
              {!queryData.video ? (
                <div
                  className="image-slide"
                  style={{ backgroundImage: `url(${queryData.image.node.childImageSharp.fluid.src})` }}
                >
                  <div className="slide-desc">
                    <h1 className="slide-title backdrop">{item.name}</h1>
                  </div>
                </div>
              ) : (
                <div className="video-slide">
                  <video poster={queryData.image.node.childImageSharp.fluid.src} autoPlay loop>
                    <source src={queryData.video.node.publicURL} type="video/webm"></source>
                  </video>
                  <div className="slide-desc">
                    <h1 className="slide-title backdrop">{item.name}</h1>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </Slider>
      <span className="headElementRef-placeholder" ref={headElementRef}></span>
      <h1 className="home-title">
        Проектиране на обществени, жилищни, промишлени сгради, интериор и дизайн, тримерни визуализации и мултимедийно
        представяне на проектите.
      </h1>
      <Grid mode="dark">
        {data.prjCategories.edges.map((item, i) => {
          const data = item.node.frontmatter;
          return (
            <CardWithoutImage
              key={`key${i}`}
              title={data.title}
              bg={data.coverImage.childImageSharp.fluid.src}
              url={item.node.fields.slug.slice(9, item.node.fields.slug.length - 1)}
            />
          );
        })}
      </Grid>
    </Layout>
  );
};

export const carousel = graphql`
  query index {
    ad: file(relativePath: { eq: "images/ad.jpg" }) {
      ...mediumImage
    }
    homeImages: allFile(filter: { relativePath: { regex: "/images/home-page/" } }) {
      edges {
        node {
          id
          base
          ...largeImage
        }
      }
    }
    homeVideos: allFile(filter: { relativePath: { regex: "/videos/" } }) {
      edges {
        node {
          id
          base
          publicURL
        }
      }
    }
    prjCategories: allMarkdownRemark(
      filter: { frontmatter: { category: { eq: true } } }
      sort: { fields: [frontmatter___index], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            index
            title
            coverImage {
              ...largeImage
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

export default IndexPage;
